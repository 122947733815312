<section class="LargeHero">
    <div class="z-5 LargeHeroContent">
        <header class="tc wrap">
            <h2 class="mb0">{{heroMainHeading}}</h2>
            <h1 class="">
                {{heroSubHeading}} <strong>+</strong>
            </h1>
            <div class="w-100 w-75-ns center">
                <p class="f4">{{heroInfo}}</p>
            </div>
            <div>
                <p class="f3 fw9">{{heroSubInfo}}</p>
            </div>
        </header>

        <a class="scroll_down pointer" [ngx-scroll-to]="'About'" [ngx-scroll-to-offset]="-30"
            title="About James Riter - Front End Web Master">
            <i class="text-white"></i>
        </a>
    </div>
</section>