<section class="fixed top-0 right-0 left-0 pv3 NavHeader" id="NavHeader">
  <div class="flex justify-between items-center wrap">
    <a class="color-inherit link fw9 f3" routerLink="/">James Riter</a>
    <div class="main-nav">
      <nav class="dn db-ns">
        <MainNavList></MainNavList>
      </nav>
      <span class="menu-trigger">
        <span (click)="shouldShow = !shouldShow" class="color-inherit pointer">
          <svg viewBox="0 0 100 80" width="40" height="40" class="" id="NavHeaderSVG">
            <rect width="90" height="10" fill="inherit"></rect>
            <rect y="30" width="90" height="10" fill="inherit"></rect>
            <rect y="60" width="90" height="10" fill="inherit"></rect>
          </svg>
        </span>
      </span>
    </div>
  </div>
</section>

<aside class="menu" [ngClass]="{slideOut: shouldShow, slideIn: !shouldShow}">
  <div class="menu-inner">
    <nav class="menu-pages list pl0 flex flex-column">
      <a class="color-inherit link mh1 ph1 pointer pv2" (click)="shouldShow = !shouldShow" title="James Riter" [ngx-scroll-to]="'Home'" [ngx-scroll-to-offset]="-40"> Home</a>
      <a class="color-inherit link mh1 ph1 pointer pv2" (click)="shouldShow = !shouldShow" title="About - James Riter" [ngx-scroll-to]="'About'" [ngx-scroll-to-offset]="-40"> About</a>
      <a class="color-inherit link mh1 ph1 pointer pv2" (click)="shouldShow = !shouldShow" title="James Riter's Work Flow" [ngx-scroll-to]="'WorkFlow'" [ngx-scroll-to-offset]="-40"> Work Flow</a>
      <a class="color-inherit link mh1 ph1 pointer pv2" (click)="shouldShow = !shouldShow" title="James Riter's Services" [ngx-scroll-to]="'Services'" [ngx-scroll-to-offset]="-40"> Services</a>
      <a class="color-inherit link mh1 ph1 pointer pv2" (click)="shouldShow = !shouldShow" title="James Riter's Portfolio" [ngx-scroll-to]="'Portfolio'" [ngx-scroll-to-offset]="-40"> Portfolio</a>
      <a class="color-inherit link mh1 ph1 pointer pv2" (click)="shouldShow = !shouldShow" title="Contact James Riter" [ngx-scroll-to]="'Contact'" [ngx-scroll-to-offset]="-40"> Contact</a>
    </nav>
  </div>
</aside>

<div class="overlay pointer" [ngClass]="{hide: shouldShow, show: !shouldShow}" (click)="shouldShow = !shouldShow"></div>