<div class="WorkFlow white pv4">
    <div class="WorkFlowIner flex flex-wrap pv5">
        <h1 class="w-100" style="display: flex;align-items: flex-end;justify-content: center;">
            3 Simple Steps to a <span class="blue ib" style="display: contents;"> Great Project</span>
        </h1>
        <div class="wrap">
            <WorkFlowSteps></WorkFlowSteps>
            <h2 class="w-100" style="display: flex;align-items: flex-end;justify-content: center;">
                All Projects Should
                <span class="flex slidingVertical slidingVerticalfadeIn">
                    <span class="blue"> Be </span>
                    <span class="blue"> Have </span>
                </span>
            </h2>
            <div class="flex flex-wrap justify-around f4 mb2">
                <span class="mb3">Clean &amp; Manageable </span>
                <span class="mb3">Mobile Ready</span>
                <span class="mb3">SEO Friendly</span>
                <span class="mb3">Optimized</span>
                <span class="mb3">Fast Load Times</span>
            </div>
        </div>
    </div>
</div>