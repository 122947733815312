<div class="mainWrap">
    <!-- nav -->
    <MainNav></MainNav>
    <!-- main content container -->
    <router-outlet></router-outlet>
    <!-- footer -->
</div>
<footer class="theFooter pv5 white bg-black">
    <div class="wrap flex justify-between">
        <span>&copy; {{ companyName }} {{ theYear }}</span>
        <span>{{siteName}}</span>
    </div>
</footer>