<div class="HireMe">
    <div class="relative z-1">
        <h2 class="f1 white">
            I Am Available For Freelance Work
        </h2>
        <a class="f3 link dim br2 ph5 pv3 mb2 dib white bg-blue pointer" [ngx-scroll-to]="'Contact'"
            [ngx-scroll-to-offset]="-30">
            Hire Me!
        </a>
    </div>
</div>