import { Component } from "@angular/core";

@Component({
  selector: "WorkFlow",
  templateUrl: "./WorkFlow.component.html",
  styleUrls: ["./WorkFlow.component.scss"]
})
export class WorkFlow {
  
}
