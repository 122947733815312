<section class="AboutME flex flex-wrap wrap">
    <div class="flex flex-wrap justify-center">
        <div class="flex flex-wrap justify-center items-center">
            <div class="w-100 w-40-ns w-40-m pa2">
                <img src="/assets/img/james-riter.jpg" alt="James Riter" class="AboutMEImage" />
                <div class="flex items-center justify-center mb3">
                    <a class="link blue mt2 glow flex items-center justify-center"
                        href="assets/resume/James-Riter-Resume-2020.pdf" target="_blank">
                        <svg class="grow h2 w2" style="height: 24px; width: 24px; margin-right:1em;">
                            <use xlink:href="#paper"></use>
                        </svg>
                        View my resume
                    </a>
                </div>
            </div>
            <div class="w-100 w-60-ns w-60-m pa2">
                <h2 class="w-100 tl mb0 f1">
                    <span class="fw9">{{aboutTitle}}</span>
                </h2>
                <h3 class="tl fw9 f3">Hello! <span class="blue">I'M James Riter.</span></h3>
                <p class="tl f4 mid-gray lh-copy">
                    {{aboutContent}}
                </p>
                <div class="flex items-center mb3">
                    <span class="fw9 mr3 dn dib-ns">
                        Find Me:
                    </span>
                    <FindMELinks></FindMELinks>
                </div>
            </div>
        </div>
    </div>
</section>