<section id="Home">
  <LargeHero>{{ siteName }}</LargeHero>
</section>
<section id="About">
  <AboutME></AboutME>
</section>
<section id="WorkFlow">
  <WorkFlow></WorkFlow>
</section>
<section id="Services">
  <MyServices></MyServices>
</section>
<section>
  <HireMe></HireMe>
</section>
<section id="Portfolio">
  <MyWork></MyWork>
</section>
<section id="Contact">
  <ContactForm></ContactForm>
</section>
<!-- <NoteItem color="black" background="orange" description="Site Notice: You account is approved"></NoteItem> -->
<svgSprite></svgSprite>